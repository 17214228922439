<template>
  <ul class="pagination">
    <li v-if="page !== 1">
      <a href="?page=1" class="pgn__btn" @click.prevent="$emit('change', 1)">
        <PaginationArrowLeftIcon />
      </a>
    </li>
    <li v-for="p in currentPages" :key="p">
      <a
        :href="`?page=${p}`"
        :class="{ 'pgn__btn--active': p === page }"
        class="pgn__btn"
        v-show="canShowPage(p)"
        @click.prevent="$emit('change', p)"
      >
        {{ p }}
      </a>
    </li>
    <li v-if="page !== total">
      <a :href="`?page=${total}`" class="pgn__btn pgn__btn--rotated" @click.prevent="$emit('change', total)">
        <PaginationArrowLeftIcon />
      </a>
    </li>
  </ul>
</template>

<script>
import PaginationArrowLeftIcon from "components/svg/PaginationArrowLeft.vue";

export default {
  name: "PaginationComponent",
  props: {
    page: Number,
    total: Number,
  },
  computed: {
    currentPages() {
      let startPage, endPage;
      const maxPages = this.total;
      if (this.total <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = this.total;
      } else {
        // total pages more than max so calculate start and end pages
        const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (this.page <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
        } else if (this.page + maxPagesAfterCurrentPage >= this.total) {
          // current page near the end
          startPage = this.total - maxPages + 1;
          endPage = this.total;
        } else {
          // current page somewhere in the middle
          startPage = this.page - maxPagesBeforeCurrentPage;
          endPage = this.page + maxPagesAfterCurrentPage;
        }
      }
      return Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);
    },
  },
  methods: {
    canShowPage(page) {
      return (
        page === this.page ||
        page === this.page - 2 ||
        page === this.page - 1 ||
        page === this.page + 1 ||
        page === this.page + 2
      );
    },
  },
  components: {
    PaginationArrowLeftIcon,
  },
};
</script>
