<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <main class="main">
    <section class="container">
      <BreadcrumbsComponent :title="!page ? pageTitle : `${pageTitle} ${page} страница`" />
      <div class="main__text" v-if="pageText">
        <EditorJSComponent :text="pageText" />
      </div>
      <div class="news" v-if="news && news.data.length">
        <div class="news__list">
          <router-link
            :to="{ name: 'article', params: { url: article.link } }"
            class="news__list__item"
            v-for="(article, index) in news.data"
            :key="index"
          >
            <time class="news__list__date" :time="article.date_publication | robotDate"
              >{{ article.date_publication | formatDate }}
            </time>
            <h2 class="news__list__title">{{ article.title }}</h2>
            <p class="news__list__desc">{{ article.description }}</p>
            <ArrowIcon />
          </router-link>
        </div>
        <nav class="pgn" v-if="news.data && news.data.length && news.total > news.per_page">
          <PaginationComponent :page="page" :total="Math.ceil(news.total / 11)" @change="getResults" />
        </nav>
      </div>
      <span class="news__empty" v-else>Информация скоро появится</span>
    </section>
  </main>
</template>

<script>
import ArrowIcon from "components/svg/Arrow.vue";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import PaginationComponent from "components/Pagination.vue";
import { cityIn } from "lvovich";

function getPage(route) {
  const urlParams = new URLSearchParams(route.fullPath.replace("/press", ""));
  const page = urlParams.get("page");
  return page ? parseInt(page) : 0;
}

export default {
  name: "NewsPage",
  async asyncData({ store, route, link }) {
    store.state.alternateLink = link;
    await store.dispatch("GET_NEWS_PAGE", getPage(route));
  },
  data() {
    return {
      page: 1,
    };
  },
  created() {
    this.page = getPage(this.$route);
  },
  computed: {
    news() {
      if (this.$store.state.news_page && this.$store.state.news_page.news) {
        return this.$store.state.news_page.news;
      } else {
        return [];
      }
    },
    pageTitle() {
      let page = {};
      let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
      city = cityIn(city ? city.title : "Казань");
      if (this.$store.state.news_page && this.$store.state.news_page.page) {
        page = this.$store.state.news_page.page;
      }
      return (page.title || page.meta_title).replace(/{{ city }}/g, `в ${city}`);
    },
    pageText() {
      if (this.pageData && this.pageData.page_texts) {
        const text = this.pageData.page_texts.find((data) => data.name === "description");
        if (text && text.text) {
          return JSON.parse(text.text).blocks && JSON.parse(text.text).blocks.length
            ? JSON.parse(text.text)
            : false;
        }
      }
      return false;
    },
    pageData() {
      if (this.$store.state.news_page && this.$store.state.news_page.page) {
        return this.$store.state.news_page.page;
      } else {
        return undefined;
      }
    },
  },
  methods: {
    getImg(item) {
      if (item.head_img && item.head_img.img && item.head_img.img.url) {
        return this.$store.state.api + item.head_img.img.url;
      }
    },
    async getResults(page) {
      this.page = page || 1;
      this.$Progress.start();
      history.pushState({}, null, this.getPushStateLink());
      await this.$store.dispatch("GET_NEWS_PAGE", page);
      window.scrollTo(0, 0);
      this.$Progress.finish();
    },
    /**
     * Метод сохраняет фильтры - создает строку с параметрами чтобы
     * сохранить в адресной строке и при перезагрузке сразу
     * применить фильтры
     */
    getPushStateLink() {
      let link = location.origin + location.pathname;

      /**
       * Форматирование
       * @param string - вот же все видно }:D
       * @returns {string}
       */
      function getParam(string) {
        return link.includes("?") ? "&" + string : "?" + string;
      }

      if (this.page && this.page !== 1) link += getParam("page=" + this.page);
      return link;
    },
  },
  metaInfo() {
    let api = this.$store.state.api;
    let alternateLink = this.$store.state.alternateLink;
    let page = {};
    let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
    city = cityIn(city ? city.title : "Казань");
    if (this.$store.state.news_page && this.$store.state.news_page.page) {
      page = this.$store.state.news_page.page;
    }
    // Не обновляет canonical при переходе через vue-router но обновляется при
    // загрузке страницы по прямой ссылке - задачу выполняет
    const canonical = !!getPage(this.$route);
    const title = (page.meta_title || page.title).replace(/{{ city }}/g, `в ${city}`);
    let metaFullPage = this.$route.fullPath;

    return {
      title,
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title,
            description: page.meta_description?.replace(/{{ city }}/g, `в ${city}`),
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "http://www.schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: "1",
                name: "Главная",
                item: api,
              },
              {
                "@type": "ListItem",
                position: "2",
                name: title,
                item: api + metaFullPage,
              },
            ],
          },
        },
      ],
      link: [
        { rel: "canonical", href: canonical ? "https://leasing-trade.ru/press" : null },
        { rel: "alternate", hreflang: "x-default", href: alternateLink },
      ],
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: page.meta_keywords,
        },
        {
          vmid: "description",
          name: "description",
          content: canonical
            ? `${page.meta_description}. Страница ${getPage(this.$route)}`
            : page.meta_description?.replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: canonical ? `${title}. Страница ${getPage(this.$route)} «Лизинг-Трейд»` : title,
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: "",
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: canonical
            ? `${page.meta_description}. Страница ${getPage(this.$route)}`
            : page.meta_description?.replace(/{{ city }}/g, `в ${city}`),
        },
      ],
    };
  },
  components: {
    PaginationComponent,
    EditorJSComponent,
    ArrowIcon,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/news.styl"
@import "~@/styles/parts/pgn.styl"
</style>
